import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import LandingPage from "../components/LandingPage"

export default ({ data }) => {
  return (
    <Layout title={"DOMINIKA BARSKA"}>
      <LandingPage
        toursImg={data.toursImg.childImageSharp.fluid}
        portfolioImg={data.portfolioImg.childImageSharp.fluid}
        middleImg={data.middleImg.childImageSharp.fluid}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    toursImg: file(relativePath: { eq: "landing_page/tours.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    portfolioImg: file(relativePath: { eq: "landing_page/photography.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    middleImg: file(relativePath: { eq: "landing_page/middle.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
