import React from "react"

import styled from "styled-components"

import { Link } from "gatsby"
import Img from "gatsby-image"

import Button from "./misc/CustomButton"

const Conatiner = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  display: grid;

  // Extra small devices (portrait phones, less than 576px)
  grid-template-columns: repeat(3, 100px);
  grid-template-rows: 300px;
  font-size: 12px;
  grid-gap: 1vmin;

  line-height: 1.2em;

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    grid-template-columns: repeat(3, 150px);
    grid-template-rows: 240px;
    font-size: 12px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 200px);
    grid-template-rows: 310px;
    font-size: 16px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 300px);
    grid-template-rows: 520px;
    font-size: 24px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1400px) {
    grid-template-columns: repeat(3, 40vh);
    grid-template-rows: 70vh;
    font-size: 24px;
  }
`

const StaticImageContainer = styled.div`
  z-index: 0;
  position: relative;
  /* padding: 0px 10px; */
  transition: 0.3s ease-in-out;

  cursor: pointer;

  .gatsby-image-wrapper {
    /* width: 100%; */
    height: 100%;
  }

  .description {
    z-index: 1;
    position: absolute;
    top: 3em;
    bottom: 0px;
    left: 0.2em;
    right: 0.2em;

    color: white;
    font-family: monument-bold, sans-serif;
    font-size: 1em;
    text-align: center;
    text-transform: uppercase;

    transition: color 1s ease-in-out;
    /* word-break: break-word; */
    overflow-wrap: break-word;
  }

  .description-center {
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 20%;
  }
`

const DynamicImageContainer = styled(StaticImageContainer)`
  &:hover {
    transform: scale(1.01);

    .description {
      color: #ffbc69;
    }
  }
`
const ButtonWrapper = styled.div`
  position: absolute;
  top: 55%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.6em;
`

function LandingPage({ toursImg, portfolioImg, middleImg }) {
  return (
    <Conatiner>
      <Wrapper>
        <DynamicImageContainer>
          <Link to="/tours">
            <div className="description">
              Bacelona <br /> Tours
            </div>
            <Img fluid={toursImg}></Img>
            <ButtonWrapper>
              <Button noShadow bgColor={"white"}>
                SEE MORE
              </Button>
            </ButtonWrapper>
          </Link>
        </DynamicImageContainer>
        <DynamicImageContainer>
          <Link to="/">
            <div className="description description-center">
              Hello,
              <br /> I'm Dominika <br />
              Tour Guide <br /> & <br /> Photographer
            </div>
            <Img fluid={middleImg}></Img>
          </Link>
        </DynamicImageContainer>
        <DynamicImageContainer>
          <Link to="/photography">
            <div className="description">
              BARSKA
              <br />
              PHOTO
              <br />
              STORIES
            </div>
            <Img fluid={portfolioImg}></Img>
            <ButtonWrapper>
              <Button noShadow bgColor={"white"}>
                SEE MORE
              </Button>
            </ButtonWrapper>
          </Link>
        </DynamicImageContainer>
      </Wrapper>
    </Conatiner>
  )
}

export default LandingPage
